export enum Path {
  HOME,
  LOGIN,
  REGISTER_INFLUENCER,
  REGISTER_PRODUCT,
  REGISTER_USER,
  FIND_PASSWORD,
  SEND_TEMP_PASSWORD,
  REGITSER_ROLE,
  MANAGE_ORDER,
  SEARCH_INFLUENCER,
  CUSTOMIZE_FILTER,
  INFLUENCER_INFO,
  REGISTER_BUY_INFO,
  NOTICE,
  ADGLOW_STORY,
  MY_PAGE,
  ADMIN,
}

export const getPath = (path: Path) => {
  switch (path) {
    case Path.HOME: {
      return '/';
    }
    case Path.LOGIN: {
      return '/login';
    }
    case Path.REGISTER_INFLUENCER: {
      return '/register/influencer';
    }
    case Path.REGISTER_PRODUCT: {
      return '/register/product';
    }
    case Path.REGISTER_USER: {
      return '/register/user';
    }
    case Path.FIND_PASSWORD: {
      return '/find-password';
    }
    case Path.SEND_TEMP_PASSWORD: {
      return '/send-temp-password';
    }
    case Path.REGITSER_ROLE: {
      return '/register/role';
    }
    case Path.SEARCH_INFLUENCER: {
      return '/search/influencer';
    }
    case Path.CUSTOMIZE_FILTER: {
      return '/customize-filter';
    }
    case Path.INFLUENCER_INFO: {
      return ':influencerUrl';
    }
    case Path.REGISTER_BUY_INFO: {
      return 'register/buy-info';
    }
    case Path.MANAGE_ORDER: {
      return '/manage-order';
    }
    case Path.MY_PAGE: {
      return '/mypage';
    }
    case Path.NOTICE: {
      return '/notice';
    }
    case Path.ADGLOW_STORY: {
      return '/adglow-story';
    }
    case Path.ADMIN: {
      return '/admin';
    }
  }
};

export const pathToString = (path: Path) => {
  switch (path) {
    case Path.HOME: {
      return 'ホーム';
    }
    case Path.LOGIN: {
      return 'ログイン';
    }
    case Path.REGISTER_INFLUENCER: {
      return '広告チャネル登録';
    }
    case Path.REGISTER_PRODUCT: {
      return '事業者登録';
    }
    case Path.REGITSER_ROLE: {
      return 'タイプ登録';
    }
    case Path.SEARCH_INFLUENCER: {
      return 'インフルエンサー検索';
    }
    case Path.CUSTOMIZE_FILTER: {
      return 'カスタマイズ検索';
    }
    case Path.INFLUENCER_INFO: {
      return 'インフルエンサー情報';
    }
    case Path.REGISTER_BUY_INFO: {
      return '広告依頼';
    }
    case Path.MANAGE_ORDER: {
      return '注文管理';
    }
    case Path.NOTICE: {
      return 'お知らせ';
    }
    case Path.ADGLOW_STORY: {
      return 'アドグローストーリー';
    }
    case Path.MY_PAGE: {
      return 'マイページ';
    }
    case Path.ADMIN: {
      return '管理者ページ';
    }
  }
};
