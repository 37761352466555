import { Routes, Route, Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import React from 'react';
import { Path, getPath } from './components/Header/path';
import { useCheckAuthenticationWithExpireTime } from './hooks/useCheckAuthentication';

const GlobalModal = React.lazy(
  () => import('./components/Modal/GlobalModal/GlobalModal'),
);

const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'));

const LoginPage = React.lazy(() => import('./pages/LoginPage/LoginPage'));

const RegisterUserPage = React.lazy(
  () => import('./pages/RegisterUserPage/RegisterUserPage'),
);

const FindPasswordPage = React.lazy(
  () => import('./pages/FindPasswordPage/FindPasswordPage'),
);

const SendTempPasswordPage = React.lazy(
  () => import('./pages/SendTempPasswordPage/SendTempPasswordPage'),
);

const RegisterRolePage = React.lazy(
  () => import('./pages/RegisterRolePage/RegisterRolePage'),
);

const RegisterInfluencerPage = React.lazy(
  () => import('./pages/RegisterInfluencerPage/RegisterInfluencerPage'),
);

const RegisterProductPage = React.lazy(
  () => import('./pages/RegisterProductPage/RegisterProductPage'),
);

const SearchInfluencerPage = React.lazy(
  () => import('./pages/SearchInfluencerPage/SearchInfluencerPage'),
);
const CustomizeFilterPage = React.lazy(
  () => import('./pages/CustomizeFilterPage/CustomizeFilterPage'),
);

const InfluencerInfoPage = React.lazy(
  () => import('./pages/InfluencerInfoPage/InfluencerInfoPage'),
);

const RegisterBuyInfoPage = React.lazy(
  () => import('./pages/RegisterBuyInfopage/RegisterBuyInfoPage'),
);

const NotFoundPage = React.lazy(
  () => import('./pages/NotFoundPage/NotFoundPage'),
);

const ManageOrderPage = React.lazy(
  () => import('./pages/ManageOrderPage/ManageOrderPage'),
);

const NoticePage = React.lazy(() => import('./pages/NoticePage/NoticePage'));

const AdGlowStoryPage = React.lazy(
  () => import('./pages/AdGlowStoryPage/AdGlowStoryPage'),
);

const MyPagePage = React.lazy(() => import('./pages/MyPage/MyPagePage'));

const AdminPage = React.lazy(() => import('./pages/AdminPage/AdminPage'));

const Alarm = React.lazy(() => import('./components/Alarm/Alarm'));

function App() {
  useCheckAuthenticationWithExpireTime();

  return (
    <div className="App bg-white text-text text-[16px]">
      <Suspense fallback={<React.Fragment />}>
        <Alarm key="alarm" />
        <GlobalModal />
      </Suspense>

      <Suspense fallback={<React.Fragment />}>
        <Routes>
          <Route path={getPath(Path.LOGIN)} element={<LoginPage />}></Route>

          <Route path={getPath(Path.HOME)} element={<HomePage />}></Route>

          <Route
            path={getPath(Path.REGISTER_USER)}
            element={<RegisterUserPage />}
          ></Route>

          <Route
            path={getPath(Path.FIND_PASSWORD)}
            element={<FindPasswordPage />}
          ></Route>

          <Route
            path={getPath(Path.SEND_TEMP_PASSWORD)}
            element={<SendTempPasswordPage />}
          ></Route>

          <Route
            path={getPath(Path.REGITSER_ROLE)}
            element={<RegisterRolePage />}
          ></Route>

          <Route
            path={getPath(Path.REGISTER_INFLUENCER)}
            element={<RegisterInfluencerPage />}
          ></Route>

          <Route
            path={getPath(Path.REGISTER_PRODUCT)}
            element={<RegisterProductPage />}
          ></Route>

          <Route
            path={getPath(Path.REGISTER_PRODUCT) + '/:storeId'}
            element={<RegisterProductPage />}
          ></Route>

          <Route
            path={getPath(Path.SEARCH_INFLUENCER)}
            element={<SearchInfluencerPage />}
          ></Route>

          <Route
            path={getPath(Path.CUSTOMIZE_FILTER)}
            element={<CustomizeFilterPage />}
          ></Route>

          <Route
            path={getPath(Path.MANAGE_ORDER)}
            element={<ManageOrderPage />}
          ></Route>

          <Route path={getPath(Path.INFLUENCER_INFO)} element={<Outlet />}>
            <Route index element={<InfluencerInfoPage />}></Route>
            <Route
              path={getPath(Path.REGISTER_BUY_INFO)}
              element={<RegisterBuyInfoPage />}
            ></Route>
          </Route>

          <Route path={getPath(Path.MY_PAGE)} element={<MyPagePage />}></Route>

          <Route path={getPath(Path.ADMIN)} element={<AdminPage />}></Route>

          <Route path={getPath(Path.NOTICE)} element={<NoticePage />}></Route>

          <Route
            path={getPath(Path.NOTICE) + '/:noticeArticleId'}
            element={<NoticePage />}
          ></Route>

          <Route
            path={getPath(Path.ADGLOW_STORY) + '/:articleId'}
            element={<AdGlowStoryPage />}
          ></Route>

          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
