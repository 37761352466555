// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.3
//   protoc               v4.25.1
// source: auth_api.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from '@bufbuild/protobuf/wire';
import { ExternalUserInfo } from './user';

export const protobufPackage = 'adglow.user';

export interface LogoutRequest {}

export interface LogoutResponse {
  error: string;
}

export interface DefaultAuthenticationRequest {
  email: string;
  password: string;
}

export interface DefaultAuthenticationResponse {
  userInfo?: ExternalUserInfo | undefined;
  error: string;
}

export interface SendEmailAuthenticationRequest {
  email: string;
  name: string;
}

export interface SendEmailAuthenticationResponse {
  error: string;
}

export interface CheckEmailAuthenticationRequest {
  authenticationNumber: string;
  email: string;
}

export interface CheckEmailAuthenticationResponse {
  result: boolean;
  error: string;
}

export interface RegisterUserResquest {
  email: string;
  password: string;
  name: string;
}

export interface RegisterUserResponse {
  userInfo: ExternalUserInfo | undefined;
  error: string;
}

export interface CreateTemporaryPasswordRequest {
  email: string;
  name: string;
}

export interface CreateTemporaryPasswordResponse {
  error: string;
}

export interface GoogleAuthenticationRequest {
  /**
   * useGoogleLogin에서 auth-code flow
   * code를 이용해서 jwt 토큰 가지고오고,
   * 그 이후는 기존의 구글 로그인과 동일
   */
  code?: string | undefined;
  /** jwt 토큰 - 여기 안에 구글이 준 userInfo 가 들어있음 */
  googleLoginToken?: string | undefined;
}

export interface GoogleAuthenticationResponse {
  userInfo: ExternalUserInfo | undefined;
  error: string;
}

/**
 * Redirect to the Kakao login page.
 * Does not return anything.
 */
export interface KakaoAuthenticationRequest {}

/** Returned from the callback url. */
export interface KakaoAuthenticationResponse {
  userInfo: ExternalUserInfo | undefined;
  error: string;
}

/** Check whether the client's cookie is fresh. */
export interface CheckAuthenticationRequest {}

export interface CheckAuthenticationResponse {
  result: boolean;
  error: string;
  userInfo: ExternalUserInfo | undefined;
}

function createBaseLogoutRequest(): LogoutRequest {
  return {};
}

export const LogoutRequest: MessageFns<LogoutRequest> = {
  encode(
    _: LogoutRequest,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LogoutRequest {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): LogoutRequest {
    return {};
  },

  toJSON(_: LogoutRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LogoutRequest>, I>>(
    base?: I,
  ): LogoutRequest {
    return LogoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogoutRequest>, I>>(
    _: I,
  ): LogoutRequest {
    const message = createBaseLogoutRequest();
    return message;
  },
};

function createBaseLogoutResponse(): LogoutResponse {
  return { error: '' };
}

export const LogoutResponse: MessageFns<LogoutResponse> = {
  encode(
    message: LogoutResponse,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.error !== '') {
      writer.uint32(10).string(message.error);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LogoutResponse {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.error = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogoutResponse {
    return {
      error: isSet(object.error) ? globalThis.String(object.error) : '',
    };
  },

  toJSON(message: LogoutResponse): unknown {
    const obj: any = {};
    if (message.error !== '') {
      obj.error = message.error;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogoutResponse>, I>>(
    base?: I,
  ): LogoutResponse {
    return LogoutResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogoutResponse>, I>>(
    object: I,
  ): LogoutResponse {
    const message = createBaseLogoutResponse();
    message.error = object.error ?? '';
    return message;
  },
};

function createBaseDefaultAuthenticationRequest(): DefaultAuthenticationRequest {
  return { email: '', password: '' };
}

export const DefaultAuthenticationRequest: MessageFns<DefaultAuthenticationRequest> =
  {
    encode(
      message: DefaultAuthenticationRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.email !== '') {
        writer.uint32(10).string(message.email);
      }
      if (message.password !== '') {
        writer.uint32(18).string(message.password);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): DefaultAuthenticationRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseDefaultAuthenticationRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.email = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.password = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): DefaultAuthenticationRequest {
      return {
        email: isSet(object.email) ? globalThis.String(object.email) : '',
        password: isSet(object.password)
          ? globalThis.String(object.password)
          : '',
      };
    },

    toJSON(message: DefaultAuthenticationRequest): unknown {
      const obj: any = {};
      if (message.email !== '') {
        obj.email = message.email;
      }
      if (message.password !== '') {
        obj.password = message.password;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<DefaultAuthenticationRequest>, I>>(
      base?: I,
    ): DefaultAuthenticationRequest {
      return DefaultAuthenticationRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<DefaultAuthenticationRequest>, I>>(
      object: I,
    ): DefaultAuthenticationRequest {
      const message = createBaseDefaultAuthenticationRequest();
      message.email = object.email ?? '';
      message.password = object.password ?? '';
      return message;
    },
  };

function createBaseDefaultAuthenticationResponse(): DefaultAuthenticationResponse {
  return { userInfo: undefined, error: '' };
}

export const DefaultAuthenticationResponse: MessageFns<DefaultAuthenticationResponse> =
  {
    encode(
      message: DefaultAuthenticationResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.userInfo !== undefined) {
        ExternalUserInfo.encode(
          message.userInfo,
          writer.uint32(10).fork(),
        ).join();
      }
      if (message.error !== '') {
        writer.uint32(18).string(message.error);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): DefaultAuthenticationResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseDefaultAuthenticationResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.userInfo = ExternalUserInfo.decode(reader, reader.uint32());
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.error = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): DefaultAuthenticationResponse {
      return {
        userInfo: isSet(object.user_info)
          ? ExternalUserInfo.fromJSON(object.user_info)
          : undefined,
        error: isSet(object.error) ? globalThis.String(object.error) : '',
      };
    },

    toJSON(message: DefaultAuthenticationResponse): unknown {
      const obj: any = {};
      if (message.userInfo !== undefined) {
        obj.user_info = ExternalUserInfo.toJSON(message.userInfo);
      }
      if (message.error !== '') {
        obj.error = message.error;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<DefaultAuthenticationResponse>, I>>(
      base?: I,
    ): DefaultAuthenticationResponse {
      return DefaultAuthenticationResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<DefaultAuthenticationResponse>, I>>(
      object: I,
    ): DefaultAuthenticationResponse {
      const message = createBaseDefaultAuthenticationResponse();
      message.userInfo =
        object.userInfo !== undefined && object.userInfo !== null
          ? ExternalUserInfo.fromPartial(object.userInfo)
          : undefined;
      message.error = object.error ?? '';
      return message;
    },
  };

function createBaseSendEmailAuthenticationRequest(): SendEmailAuthenticationRequest {
  return { email: '', name: '' };
}

export const SendEmailAuthenticationRequest: MessageFns<SendEmailAuthenticationRequest> =
  {
    encode(
      message: SendEmailAuthenticationRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.email !== '') {
        writer.uint32(10).string(message.email);
      }
      if (message.name !== '') {
        writer.uint32(18).string(message.name);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): SendEmailAuthenticationRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseSendEmailAuthenticationRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.email = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.name = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): SendEmailAuthenticationRequest {
      return {
        email: isSet(object.email) ? globalThis.String(object.email) : '',
        name: isSet(object.name) ? globalThis.String(object.name) : '',
      };
    },

    toJSON(message: SendEmailAuthenticationRequest): unknown {
      const obj: any = {};
      if (message.email !== '') {
        obj.email = message.email;
      }
      if (message.name !== '') {
        obj.name = message.name;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<SendEmailAuthenticationRequest>, I>>(
      base?: I,
    ): SendEmailAuthenticationRequest {
      return SendEmailAuthenticationRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<SendEmailAuthenticationRequest>, I>,
    >(object: I): SendEmailAuthenticationRequest {
      const message = createBaseSendEmailAuthenticationRequest();
      message.email = object.email ?? '';
      message.name = object.name ?? '';
      return message;
    },
  };

function createBaseSendEmailAuthenticationResponse(): SendEmailAuthenticationResponse {
  return { error: '' };
}

export const SendEmailAuthenticationResponse: MessageFns<SendEmailAuthenticationResponse> =
  {
    encode(
      message: SendEmailAuthenticationResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.error !== '') {
        writer.uint32(10).string(message.error);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): SendEmailAuthenticationResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseSendEmailAuthenticationResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.error = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): SendEmailAuthenticationResponse {
      return {
        error: isSet(object.error) ? globalThis.String(object.error) : '',
      };
    },

    toJSON(message: SendEmailAuthenticationResponse): unknown {
      const obj: any = {};
      if (message.error !== '') {
        obj.error = message.error;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<SendEmailAuthenticationResponse>, I>>(
      base?: I,
    ): SendEmailAuthenticationResponse {
      return SendEmailAuthenticationResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<SendEmailAuthenticationResponse>, I>,
    >(object: I): SendEmailAuthenticationResponse {
      const message = createBaseSendEmailAuthenticationResponse();
      message.error = object.error ?? '';
      return message;
    },
  };

function createBaseCheckEmailAuthenticationRequest(): CheckEmailAuthenticationRequest {
  return { authenticationNumber: '', email: '' };
}

export const CheckEmailAuthenticationRequest: MessageFns<CheckEmailAuthenticationRequest> =
  {
    encode(
      message: CheckEmailAuthenticationRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.authenticationNumber !== '') {
        writer.uint32(10).string(message.authenticationNumber);
      }
      if (message.email !== '') {
        writer.uint32(18).string(message.email);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): CheckEmailAuthenticationRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCheckEmailAuthenticationRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.authenticationNumber = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.email = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CheckEmailAuthenticationRequest {
      return {
        authenticationNumber: isSet(object.authentication_number)
          ? globalThis.String(object.authentication_number)
          : '',
        email: isSet(object.email) ? globalThis.String(object.email) : '',
      };
    },

    toJSON(message: CheckEmailAuthenticationRequest): unknown {
      const obj: any = {};
      if (message.authenticationNumber !== '') {
        obj.authentication_number = message.authenticationNumber;
      }
      if (message.email !== '') {
        obj.email = message.email;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<CheckEmailAuthenticationRequest>, I>>(
      base?: I,
    ): CheckEmailAuthenticationRequest {
      return CheckEmailAuthenticationRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<CheckEmailAuthenticationRequest>, I>,
    >(object: I): CheckEmailAuthenticationRequest {
      const message = createBaseCheckEmailAuthenticationRequest();
      message.authenticationNumber = object.authenticationNumber ?? '';
      message.email = object.email ?? '';
      return message;
    },
  };

function createBaseCheckEmailAuthenticationResponse(): CheckEmailAuthenticationResponse {
  return { result: false, error: '' };
}

export const CheckEmailAuthenticationResponse: MessageFns<CheckEmailAuthenticationResponse> =
  {
    encode(
      message: CheckEmailAuthenticationResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.result !== false) {
        writer.uint32(8).bool(message.result);
      }
      if (message.error !== '') {
        writer.uint32(18).string(message.error);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): CheckEmailAuthenticationResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCheckEmailAuthenticationResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 8) {
              break;
            }

            message.result = reader.bool();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.error = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CheckEmailAuthenticationResponse {
      return {
        result: isSet(object.result)
          ? globalThis.Boolean(object.result)
          : false,
        error: isSet(object.error) ? globalThis.String(object.error) : '',
      };
    },

    toJSON(message: CheckEmailAuthenticationResponse): unknown {
      const obj: any = {};
      if (message.result !== false) {
        obj.result = message.result;
      }
      if (message.error !== '') {
        obj.error = message.error;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<CheckEmailAuthenticationResponse>, I>>(
      base?: I,
    ): CheckEmailAuthenticationResponse {
      return CheckEmailAuthenticationResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<CheckEmailAuthenticationResponse>, I>,
    >(object: I): CheckEmailAuthenticationResponse {
      const message = createBaseCheckEmailAuthenticationResponse();
      message.result = object.result ?? false;
      message.error = object.error ?? '';
      return message;
    },
  };

function createBaseRegisterUserResquest(): RegisterUserResquest {
  return { email: '', password: '', name: '' };
}

export const RegisterUserResquest: MessageFns<RegisterUserResquest> = {
  encode(
    message: RegisterUserResquest,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.email !== '') {
      writer.uint32(10).string(message.email);
    }
    if (message.password !== '') {
      writer.uint32(18).string(message.password);
    }
    if (message.name !== '') {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(
    input: BinaryReader | Uint8Array,
    length?: number,
  ): RegisterUserResquest {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterUserResquest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterUserResquest {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : '',
      password: isSet(object.password)
        ? globalThis.String(object.password)
        : '',
      name: isSet(object.name) ? globalThis.String(object.name) : '',
    };
  },

  toJSON(message: RegisterUserResquest): unknown {
    const obj: any = {};
    if (message.email !== '') {
      obj.email = message.email;
    }
    if (message.password !== '') {
      obj.password = message.password;
    }
    if (message.name !== '') {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterUserResquest>, I>>(
    base?: I,
  ): RegisterUserResquest {
    return RegisterUserResquest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterUserResquest>, I>>(
    object: I,
  ): RegisterUserResquest {
    const message = createBaseRegisterUserResquest();
    message.email = object.email ?? '';
    message.password = object.password ?? '';
    message.name = object.name ?? '';
    return message;
  },
};

function createBaseRegisterUserResponse(): RegisterUserResponse {
  return { userInfo: undefined, error: '' };
}

export const RegisterUserResponse: MessageFns<RegisterUserResponse> = {
  encode(
    message: RegisterUserResponse,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.userInfo !== undefined) {
      ExternalUserInfo.encode(
        message.userInfo,
        writer.uint32(10).fork(),
      ).join();
    }
    if (message.error !== '') {
      writer.uint32(18).string(message.error);
    }
    return writer;
  },

  decode(
    input: BinaryReader | Uint8Array,
    length?: number,
  ): RegisterUserResponse {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.userInfo = ExternalUserInfo.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.error = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterUserResponse {
    return {
      userInfo: isSet(object.user_info)
        ? ExternalUserInfo.fromJSON(object.user_info)
        : undefined,
      error: isSet(object.error) ? globalThis.String(object.error) : '',
    };
  },

  toJSON(message: RegisterUserResponse): unknown {
    const obj: any = {};
    if (message.userInfo !== undefined) {
      obj.user_info = ExternalUserInfo.toJSON(message.userInfo);
    }
    if (message.error !== '') {
      obj.error = message.error;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterUserResponse>, I>>(
    base?: I,
  ): RegisterUserResponse {
    return RegisterUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterUserResponse>, I>>(
    object: I,
  ): RegisterUserResponse {
    const message = createBaseRegisterUserResponse();
    message.userInfo =
      object.userInfo !== undefined && object.userInfo !== null
        ? ExternalUserInfo.fromPartial(object.userInfo)
        : undefined;
    message.error = object.error ?? '';
    return message;
  },
};

function createBaseCreateTemporaryPasswordRequest(): CreateTemporaryPasswordRequest {
  return { email: '', name: '' };
}

export const CreateTemporaryPasswordRequest: MessageFns<CreateTemporaryPasswordRequest> =
  {
    encode(
      message: CreateTemporaryPasswordRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.email !== '') {
        writer.uint32(10).string(message.email);
      }
      if (message.name !== '') {
        writer.uint32(18).string(message.name);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): CreateTemporaryPasswordRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCreateTemporaryPasswordRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.email = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.name = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CreateTemporaryPasswordRequest {
      return {
        email: isSet(object.email) ? globalThis.String(object.email) : '',
        name: isSet(object.name) ? globalThis.String(object.name) : '',
      };
    },

    toJSON(message: CreateTemporaryPasswordRequest): unknown {
      const obj: any = {};
      if (message.email !== '') {
        obj.email = message.email;
      }
      if (message.name !== '') {
        obj.name = message.name;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<CreateTemporaryPasswordRequest>, I>>(
      base?: I,
    ): CreateTemporaryPasswordRequest {
      return CreateTemporaryPasswordRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<CreateTemporaryPasswordRequest>, I>,
    >(object: I): CreateTemporaryPasswordRequest {
      const message = createBaseCreateTemporaryPasswordRequest();
      message.email = object.email ?? '';
      message.name = object.name ?? '';
      return message;
    },
  };

function createBaseCreateTemporaryPasswordResponse(): CreateTemporaryPasswordResponse {
  return { error: '' };
}

export const CreateTemporaryPasswordResponse: MessageFns<CreateTemporaryPasswordResponse> =
  {
    encode(
      message: CreateTemporaryPasswordResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.error !== '') {
        writer.uint32(10).string(message.error);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): CreateTemporaryPasswordResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCreateTemporaryPasswordResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.error = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CreateTemporaryPasswordResponse {
      return {
        error: isSet(object.error) ? globalThis.String(object.error) : '',
      };
    },

    toJSON(message: CreateTemporaryPasswordResponse): unknown {
      const obj: any = {};
      if (message.error !== '') {
        obj.error = message.error;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<CreateTemporaryPasswordResponse>, I>>(
      base?: I,
    ): CreateTemporaryPasswordResponse {
      return CreateTemporaryPasswordResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<CreateTemporaryPasswordResponse>, I>,
    >(object: I): CreateTemporaryPasswordResponse {
      const message = createBaseCreateTemporaryPasswordResponse();
      message.error = object.error ?? '';
      return message;
    },
  };

function createBaseGoogleAuthenticationRequest(): GoogleAuthenticationRequest {
  return { code: undefined, googleLoginToken: undefined };
}

export const GoogleAuthenticationRequest: MessageFns<GoogleAuthenticationRequest> =
  {
    encode(
      message: GoogleAuthenticationRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.code !== undefined) {
        writer.uint32(10).string(message.code);
      }
      if (message.googleLoginToken !== undefined) {
        writer.uint32(18).string(message.googleLoginToken);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): GoogleAuthenticationRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseGoogleAuthenticationRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.code = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.googleLoginToken = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): GoogleAuthenticationRequest {
      return {
        code: isSet(object.code) ? globalThis.String(object.code) : undefined,
        googleLoginToken: isSet(object.google_login_token)
          ? globalThis.String(object.google_login_token)
          : undefined,
      };
    },

    toJSON(message: GoogleAuthenticationRequest): unknown {
      const obj: any = {};
      if (message.code !== undefined) {
        obj.code = message.code;
      }
      if (message.googleLoginToken !== undefined) {
        obj.google_login_token = message.googleLoginToken;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<GoogleAuthenticationRequest>, I>>(
      base?: I,
    ): GoogleAuthenticationRequest {
      return GoogleAuthenticationRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<GoogleAuthenticationRequest>, I>>(
      object: I,
    ): GoogleAuthenticationRequest {
      const message = createBaseGoogleAuthenticationRequest();
      message.code = object.code ?? undefined;
      message.googleLoginToken = object.googleLoginToken ?? undefined;
      return message;
    },
  };

function createBaseGoogleAuthenticationResponse(): GoogleAuthenticationResponse {
  return { userInfo: undefined, error: '' };
}

export const GoogleAuthenticationResponse: MessageFns<GoogleAuthenticationResponse> =
  {
    encode(
      message: GoogleAuthenticationResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.userInfo !== undefined) {
        ExternalUserInfo.encode(
          message.userInfo,
          writer.uint32(10).fork(),
        ).join();
      }
      if (message.error !== '') {
        writer.uint32(18).string(message.error);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): GoogleAuthenticationResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseGoogleAuthenticationResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.userInfo = ExternalUserInfo.decode(reader, reader.uint32());
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.error = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): GoogleAuthenticationResponse {
      return {
        userInfo: isSet(object.user_info)
          ? ExternalUserInfo.fromJSON(object.user_info)
          : undefined,
        error: isSet(object.error) ? globalThis.String(object.error) : '',
      };
    },

    toJSON(message: GoogleAuthenticationResponse): unknown {
      const obj: any = {};
      if (message.userInfo !== undefined) {
        obj.user_info = ExternalUserInfo.toJSON(message.userInfo);
      }
      if (message.error !== '') {
        obj.error = message.error;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<GoogleAuthenticationResponse>, I>>(
      base?: I,
    ): GoogleAuthenticationResponse {
      return GoogleAuthenticationResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<GoogleAuthenticationResponse>, I>>(
      object: I,
    ): GoogleAuthenticationResponse {
      const message = createBaseGoogleAuthenticationResponse();
      message.userInfo =
        object.userInfo !== undefined && object.userInfo !== null
          ? ExternalUserInfo.fromPartial(object.userInfo)
          : undefined;
      message.error = object.error ?? '';
      return message;
    },
  };

function createBaseKakaoAuthenticationRequest(): KakaoAuthenticationRequest {
  return {};
}

export const KakaoAuthenticationRequest: MessageFns<KakaoAuthenticationRequest> =
  {
    encode(
      _: KakaoAuthenticationRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): KakaoAuthenticationRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseKakaoAuthenticationRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(_: any): KakaoAuthenticationRequest {
      return {};
    },

    toJSON(_: KakaoAuthenticationRequest): unknown {
      const obj: any = {};
      return obj;
    },

    create<I extends Exact<DeepPartial<KakaoAuthenticationRequest>, I>>(
      base?: I,
    ): KakaoAuthenticationRequest {
      return KakaoAuthenticationRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<KakaoAuthenticationRequest>, I>>(
      _: I,
    ): KakaoAuthenticationRequest {
      const message = createBaseKakaoAuthenticationRequest();
      return message;
    },
  };

function createBaseKakaoAuthenticationResponse(): KakaoAuthenticationResponse {
  return { userInfo: undefined, error: '' };
}

export const KakaoAuthenticationResponse: MessageFns<KakaoAuthenticationResponse> =
  {
    encode(
      message: KakaoAuthenticationResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.userInfo !== undefined) {
        ExternalUserInfo.encode(
          message.userInfo,
          writer.uint32(10).fork(),
        ).join();
      }
      if (message.error !== '') {
        writer.uint32(18).string(message.error);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): KakaoAuthenticationResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseKakaoAuthenticationResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.userInfo = ExternalUserInfo.decode(reader, reader.uint32());
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.error = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): KakaoAuthenticationResponse {
      return {
        userInfo: isSet(object.user_info)
          ? ExternalUserInfo.fromJSON(object.user_info)
          : undefined,
        error: isSet(object.error) ? globalThis.String(object.error) : '',
      };
    },

    toJSON(message: KakaoAuthenticationResponse): unknown {
      const obj: any = {};
      if (message.userInfo !== undefined) {
        obj.user_info = ExternalUserInfo.toJSON(message.userInfo);
      }
      if (message.error !== '') {
        obj.error = message.error;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<KakaoAuthenticationResponse>, I>>(
      base?: I,
    ): KakaoAuthenticationResponse {
      return KakaoAuthenticationResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<KakaoAuthenticationResponse>, I>>(
      object: I,
    ): KakaoAuthenticationResponse {
      const message = createBaseKakaoAuthenticationResponse();
      message.userInfo =
        object.userInfo !== undefined && object.userInfo !== null
          ? ExternalUserInfo.fromPartial(object.userInfo)
          : undefined;
      message.error = object.error ?? '';
      return message;
    },
  };

function createBaseCheckAuthenticationRequest(): CheckAuthenticationRequest {
  return {};
}

export const CheckAuthenticationRequest: MessageFns<CheckAuthenticationRequest> =
  {
    encode(
      _: CheckAuthenticationRequest,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): CheckAuthenticationRequest {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCheckAuthenticationRequest();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(_: any): CheckAuthenticationRequest {
      return {};
    },

    toJSON(_: CheckAuthenticationRequest): unknown {
      const obj: any = {};
      return obj;
    },

    create<I extends Exact<DeepPartial<CheckAuthenticationRequest>, I>>(
      base?: I,
    ): CheckAuthenticationRequest {
      return CheckAuthenticationRequest.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<CheckAuthenticationRequest>, I>>(
      _: I,
    ): CheckAuthenticationRequest {
      const message = createBaseCheckAuthenticationRequest();
      return message;
    },
  };

function createBaseCheckAuthenticationResponse(): CheckAuthenticationResponse {
  return { result: false, error: '', userInfo: undefined };
}

export const CheckAuthenticationResponse: MessageFns<CheckAuthenticationResponse> =
  {
    encode(
      message: CheckAuthenticationResponse,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.result !== false) {
        writer.uint32(8).bool(message.result);
      }
      if (message.error !== '') {
        writer.uint32(18).string(message.error);
      }
      if (message.userInfo !== undefined) {
        ExternalUserInfo.encode(
          message.userInfo,
          writer.uint32(26).fork(),
        ).join();
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): CheckAuthenticationResponse {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCheckAuthenticationResponse();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 8) {
              break;
            }

            message.result = reader.bool();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.error = reader.string();
            continue;
          }
          case 3: {
            if (tag !== 26) {
              break;
            }

            message.userInfo = ExternalUserInfo.decode(reader, reader.uint32());
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CheckAuthenticationResponse {
      return {
        result: isSet(object.result)
          ? globalThis.Boolean(object.result)
          : false,
        error: isSet(object.error) ? globalThis.String(object.error) : '',
        userInfo: isSet(object.user_info)
          ? ExternalUserInfo.fromJSON(object.user_info)
          : undefined,
      };
    },

    toJSON(message: CheckAuthenticationResponse): unknown {
      const obj: any = {};
      if (message.result !== false) {
        obj.result = message.result;
      }
      if (message.error !== '') {
        obj.error = message.error;
      }
      if (message.userInfo !== undefined) {
        obj.user_info = ExternalUserInfo.toJSON(message.userInfo);
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<CheckAuthenticationResponse>, I>>(
      base?: I,
    ): CheckAuthenticationResponse {
      return CheckAuthenticationResponse.fromPartial(base ?? ({} as any));
    },
    fromPartial<I extends Exact<DeepPartial<CheckAuthenticationResponse>, I>>(
      object: I,
    ): CheckAuthenticationResponse {
      const message = createBaseCheckAuthenticationResponse();
      message.result = object.result ?? false;
      message.error = object.error ?? '';
      message.userInfo =
        object.userInfo !== undefined && object.userInfo !== null
          ? ExternalUserInfo.fromPartial(object.userInfo)
          : undefined;
      return message;
    },
  };

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
