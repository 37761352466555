// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.3
//   protoc               v4.25.1
// source: user.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from '@bufbuild/protobuf/wire';
import { Timestamp } from './google/protobuf/timestamp';

export const protobufPackage = 'adglow.user';

export enum Role {
  ROLE_UNSPECIFIED = 0,
  INFLUENCER = 1,
  SELLER = 2,
  UNRECOGNIZED = -1,
}

export function roleFromJSON(object: any): Role {
  switch (object) {
    case 0:
    case 'ROLE_UNSPECIFIED':
      return Role.ROLE_UNSPECIFIED;
    case 1:
    case 'INFLUENCER':
      return Role.INFLUENCER;
    case 2:
    case 'SELLER':
      return Role.SELLER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Role.UNRECOGNIZED;
  }
}

export function roleToJSON(object: Role): number {
  switch (object) {
    case Role.ROLE_UNSPECIFIED:
      return 0;
    case Role.INFLUENCER:
      return 1;
    case Role.SELLER:
      return 2;
    case Role.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum ProductCategory {
  PRODUCT_CATEGORY_UNSPECIFIED = 0,
  FOOD = 1,
  CAFE = 2,
  UNRECOGNIZED = -1,
}

export function productCategoryFromJSON(object: any): ProductCategory {
  switch (object) {
    case 0:
    case 'PRODUCT_CATEGORY_UNSPECIFIED':
      return ProductCategory.PRODUCT_CATEGORY_UNSPECIFIED;
    case 1:
    case 'FOOD':
      return ProductCategory.FOOD;
    case 2:
    case 'CAFE':
      return ProductCategory.CAFE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProductCategory.UNRECOGNIZED;
  }
}

export function productCategoryToJSON(object: ProductCategory): number {
  switch (object) {
    case ProductCategory.PRODUCT_CATEGORY_UNSPECIFIED:
      return 0;
    case ProductCategory.FOOD:
      return 1;
    case ProductCategory.CAFE:
      return 2;
    case ProductCategory.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum LoginType {
  LOGIN_TYPE_UNSPECIFIED = 0,
  GOOGLE = 1,
  AMAZON = 2,
  DEFAULT = 3,
  LINE = 4,
  UNRECOGNIZED = -1,
}

export function loginTypeFromJSON(object: any): LoginType {
  switch (object) {
    case 0:
    case 'LOGIN_TYPE_UNSPECIFIED':
      return LoginType.LOGIN_TYPE_UNSPECIFIED;
    case 1:
    case 'GOOGLE':
      return LoginType.GOOGLE;
    case 2:
    case 'AMAZON':
      return LoginType.AMAZON;
    case 3:
    case 'DEFAULT':
      return LoginType.DEFAULT;
    case 4:
    case 'LINE':
      return LoginType.LINE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LoginType.UNRECOGNIZED;
  }
}

export function loginTypeToJSON(object: LoginType): number {
  switch (object) {
    case LoginType.LOGIN_TYPE_UNSPECIFIED:
      return 0;
    case LoginType.GOOGLE:
      return 1;
    case LoginType.AMAZON:
      return 2;
    case LoginType.DEFAULT:
      return 3;
    case LoginType.LINE:
      return 4;
    case LoginType.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * Basic user information.
 *
 * Do not share it externally.
 */
export interface UserInfo {
  userId: number;
  /** Authentication ID given by external login services. */
  authId: string;
  /** User name. */
  name: string;
  /** User email. */
  email: string;
  /** Path to the user thumbnail image. */
  thumbnailImagePath?: string | undefined;
  /** Time that the user joined the service. */
  joinTime: Date | undefined;
  /** Last time that the user logged in. */
  lastLogin: Date | undefined;
  totalNumVisitDays: number;
  /** Whether the user is an admin. */
  isAdmin: boolean;
  numContinuousLoginDays: number;
  role: Role;
  serviceCheckTime?: Date | undefined;
  privacyCheckTime?: Date | undefined;
  marketingCheckTime?: Date | undefined;
  loginType: LoginType;
  productCategoryList: ProductCategory[];
  isTest: boolean;
  password: string;
}

/** User info that is returned to the client. */
export interface ExternalUserInfo {
  name: string;
  email: string;
  thumbnailImagePath?: string | undefined;
  joinTime: Date | undefined;
  lastLogin: Date | undefined;
  totalNumVisitDays: number;
  isAdmin: boolean;
  numContinuousLoginDays: number;
  role: Role;
  serviceCheckTime?: Date | undefined;
  privacyCheckTime?: Date | undefined;
  marketingCheckTime?: Date | undefined;
  loginType: LoginType;
  productCategoryList: ProductCategory[];
  isTest: boolean;
}

export interface ExternalUserInfoWithUserIdInfo {
  name: string;
  email: string;
  thumbnailImagePath?: string | undefined;
  joinTime: Date | undefined;
  lastLogin: Date | undefined;
  totalNumVisitDays: number;
  isAdmin: boolean;
  numContinuousLoginDays: number;
  role: Role;
  serviceCheckTime?: Date | undefined;
  privacyCheckTime?: Date | undefined;
  marketingCheckTime?: Date | undefined;
  loginType: LoginType;
  userId: number;
  productCategoryList: ProductCategory[];
  isTest: boolean;
}

function createBaseUserInfo(): UserInfo {
  return {
    userId: 0,
    authId: '',
    name: '',
    email: '',
    thumbnailImagePath: undefined,
    joinTime: undefined,
    lastLogin: undefined,
    totalNumVisitDays: 0,
    isAdmin: false,
    numContinuousLoginDays: 0,
    role: 0,
    serviceCheckTime: undefined,
    privacyCheckTime: undefined,
    marketingCheckTime: undefined,
    loginType: 0,
    productCategoryList: [],
    isTest: false,
    password: '',
  };
}

export const UserInfo: MessageFns<UserInfo> = {
  encode(
    message: UserInfo,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.userId !== 0) {
      writer.uint32(8).int32(message.userId);
    }
    if (message.authId !== '') {
      writer.uint32(18).string(message.authId);
    }
    if (message.name !== '') {
      writer.uint32(26).string(message.name);
    }
    if (message.email !== '') {
      writer.uint32(34).string(message.email);
    }
    if (message.thumbnailImagePath !== undefined) {
      writer.uint32(42).string(message.thumbnailImagePath);
    }
    if (message.joinTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.joinTime),
        writer.uint32(50).fork(),
      ).join();
    }
    if (message.lastLogin !== undefined) {
      Timestamp.encode(
        toTimestamp(message.lastLogin),
        writer.uint32(58).fork(),
      ).join();
    }
    if (message.totalNumVisitDays !== 0) {
      writer.uint32(64).int32(message.totalNumVisitDays);
    }
    if (message.isAdmin !== false) {
      writer.uint32(72).bool(message.isAdmin);
    }
    if (message.numContinuousLoginDays !== 0) {
      writer.uint32(80).int32(message.numContinuousLoginDays);
    }
    if (message.role !== 0) {
      writer.uint32(88).int32(message.role);
    }
    if (message.serviceCheckTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.serviceCheckTime),
        writer.uint32(98).fork(),
      ).join();
    }
    if (message.privacyCheckTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.privacyCheckTime),
        writer.uint32(106).fork(),
      ).join();
    }
    if (message.marketingCheckTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.marketingCheckTime),
        writer.uint32(114).fork(),
      ).join();
    }
    if (message.loginType !== 0) {
      writer.uint32(128).int32(message.loginType);
    }
    writer.uint32(138).fork();
    for (const v of message.productCategoryList) {
      writer.int32(v);
    }
    writer.join();
    if (message.isTest !== false) {
      writer.uint32(144).bool(message.isTest);
    }
    if (message.password !== '') {
      writer.uint32(154).string(message.password);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserInfo {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.userId = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.authId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.thumbnailImagePath = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.joinTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.lastLogin = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.totalNumVisitDays = reader.int32();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.isAdmin = reader.bool();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.numContinuousLoginDays = reader.int32();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.serviceCheckTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.privacyCheckTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.marketingCheckTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.loginType = reader.int32() as any;
          continue;
        }
        case 17: {
          if (tag === 136) {
            message.productCategoryList.push(reader.int32() as any);

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.productCategoryList.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 18: {
          if (tag !== 144) {
            break;
          }

          message.isTest = reader.bool();
          continue;
        }
        case 19: {
          if (tag !== 154) {
            break;
          }

          message.password = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInfo {
    return {
      userId: isSet(object.user_id) ? globalThis.Number(object.user_id) : 0,
      authId: isSet(object.auth_id) ? globalThis.String(object.auth_id) : '',
      name: isSet(object.name) ? globalThis.String(object.name) : '',
      email: isSet(object.email) ? globalThis.String(object.email) : '',
      thumbnailImagePath: isSet(object.thumbnail_image_path)
        ? globalThis.String(object.thumbnail_image_path)
        : undefined,
      joinTime: isSet(object.join_time)
        ? fromJsonTimestamp(object.join_time)
        : undefined,
      lastLogin: isSet(object.last_login)
        ? fromJsonTimestamp(object.last_login)
        : undefined,
      totalNumVisitDays: isSet(object.total_num_visit_days)
        ? globalThis.Number(object.total_num_visit_days)
        : 0,
      isAdmin: isSet(object.is_admin)
        ? globalThis.Boolean(object.is_admin)
        : false,
      numContinuousLoginDays: isSet(object.num_continuous_login_days)
        ? globalThis.Number(object.num_continuous_login_days)
        : 0,
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
      serviceCheckTime: isSet(object.service_check_time)
        ? fromJsonTimestamp(object.service_check_time)
        : undefined,
      privacyCheckTime: isSet(object.privacy_check_time)
        ? fromJsonTimestamp(object.privacy_check_time)
        : undefined,
      marketingCheckTime: isSet(object.marketing_check_time)
        ? fromJsonTimestamp(object.marketing_check_time)
        : undefined,
      loginType: isSet(object.login_type)
        ? loginTypeFromJSON(object.login_type)
        : 0,
      productCategoryList: globalThis.Array.isArray(
        object?.product_category_list,
      )
        ? object.product_category_list.map((e: any) =>
            productCategoryFromJSON(e),
          )
        : [],
      isTest: isSet(object.is_test)
        ? globalThis.Boolean(object.is_test)
        : false,
      password: isSet(object.password)
        ? globalThis.String(object.password)
        : '',
    };
  },

  toJSON(message: UserInfo): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.user_id = Math.round(message.userId);
    }
    if (message.authId !== '') {
      obj.auth_id = message.authId;
    }
    if (message.name !== '') {
      obj.name = message.name;
    }
    if (message.email !== '') {
      obj.email = message.email;
    }
    if (message.thumbnailImagePath !== undefined) {
      obj.thumbnail_image_path = message.thumbnailImagePath;
    }
    if (message.joinTime !== undefined) {
      obj.join_time = message.joinTime.toISOString();
    }
    if (message.lastLogin !== undefined) {
      obj.last_login = message.lastLogin.toISOString();
    }
    if (message.totalNumVisitDays !== 0) {
      obj.total_num_visit_days = Math.round(message.totalNumVisitDays);
    }
    if (message.isAdmin !== false) {
      obj.is_admin = message.isAdmin;
    }
    if (message.numContinuousLoginDays !== 0) {
      obj.num_continuous_login_days = Math.round(
        message.numContinuousLoginDays,
      );
    }
    if (message.role !== 0) {
      obj.role = roleToJSON(message.role);
    }
    if (message.serviceCheckTime !== undefined) {
      obj.service_check_time = message.serviceCheckTime.toISOString();
    }
    if (message.privacyCheckTime !== undefined) {
      obj.privacy_check_time = message.privacyCheckTime.toISOString();
    }
    if (message.marketingCheckTime !== undefined) {
      obj.marketing_check_time = message.marketingCheckTime.toISOString();
    }
    if (message.loginType !== 0) {
      obj.login_type = loginTypeToJSON(message.loginType);
    }
    if (message.productCategoryList?.length) {
      obj.product_category_list = message.productCategoryList.map((e) =>
        productCategoryToJSON(e),
      );
    }
    if (message.isTest !== false) {
      obj.is_test = message.isTest;
    }
    if (message.password !== '') {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInfo>, I>>(base?: I): UserInfo {
    return UserInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserInfo>, I>>(object: I): UserInfo {
    const message = createBaseUserInfo();
    message.userId = object.userId ?? 0;
    message.authId = object.authId ?? '';
    message.name = object.name ?? '';
    message.email = object.email ?? '';
    message.thumbnailImagePath = object.thumbnailImagePath ?? undefined;
    message.joinTime = object.joinTime ?? undefined;
    message.lastLogin = object.lastLogin ?? undefined;
    message.totalNumVisitDays = object.totalNumVisitDays ?? 0;
    message.isAdmin = object.isAdmin ?? false;
    message.numContinuousLoginDays = object.numContinuousLoginDays ?? 0;
    message.role = object.role ?? 0;
    message.serviceCheckTime = object.serviceCheckTime ?? undefined;
    message.privacyCheckTime = object.privacyCheckTime ?? undefined;
    message.marketingCheckTime = object.marketingCheckTime ?? undefined;
    message.loginType = object.loginType ?? 0;
    message.productCategoryList =
      object.productCategoryList?.map((e) => e) || [];
    message.isTest = object.isTest ?? false;
    message.password = object.password ?? '';
    return message;
  },
};

function createBaseExternalUserInfo(): ExternalUserInfo {
  return {
    name: '',
    email: '',
    thumbnailImagePath: undefined,
    joinTime: undefined,
    lastLogin: undefined,
    totalNumVisitDays: 0,
    isAdmin: false,
    numContinuousLoginDays: 0,
    role: 0,
    serviceCheckTime: undefined,
    privacyCheckTime: undefined,
    marketingCheckTime: undefined,
    loginType: 0,
    productCategoryList: [],
    isTest: false,
  };
}

export const ExternalUserInfo: MessageFns<ExternalUserInfo> = {
  encode(
    message: ExternalUserInfo,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== '') {
      writer.uint32(18).string(message.email);
    }
    if (message.thumbnailImagePath !== undefined) {
      writer.uint32(26).string(message.thumbnailImagePath);
    }
    if (message.joinTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.joinTime),
        writer.uint32(34).fork(),
      ).join();
    }
    if (message.lastLogin !== undefined) {
      Timestamp.encode(
        toTimestamp(message.lastLogin),
        writer.uint32(42).fork(),
      ).join();
    }
    if (message.totalNumVisitDays !== 0) {
      writer.uint32(48).int32(message.totalNumVisitDays);
    }
    if (message.isAdmin !== false) {
      writer.uint32(56).bool(message.isAdmin);
    }
    if (message.numContinuousLoginDays !== 0) {
      writer.uint32(64).int32(message.numContinuousLoginDays);
    }
    if (message.role !== 0) {
      writer.uint32(72).int32(message.role);
    }
    if (message.serviceCheckTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.serviceCheckTime),
        writer.uint32(82).fork(),
      ).join();
    }
    if (message.privacyCheckTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.privacyCheckTime),
        writer.uint32(90).fork(),
      ).join();
    }
    if (message.marketingCheckTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.marketingCheckTime),
        writer.uint32(98).fork(),
      ).join();
    }
    if (message.loginType !== 0) {
      writer.uint32(104).int32(message.loginType);
    }
    writer.uint32(114).fork();
    for (const v of message.productCategoryList) {
      writer.int32(v);
    }
    writer.join();
    if (message.isTest !== false) {
      writer.uint32(120).bool(message.isTest);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExternalUserInfo {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.thumbnailImagePath = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.joinTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.lastLogin = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.totalNumVisitDays = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.isAdmin = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.numContinuousLoginDays = reader.int32();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.serviceCheckTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.privacyCheckTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.marketingCheckTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.loginType = reader.int32() as any;
          continue;
        }
        case 14: {
          if (tag === 112) {
            message.productCategoryList.push(reader.int32() as any);

            continue;
          }

          if (tag === 114) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.productCategoryList.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.isTest = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalUserInfo {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : '',
      email: isSet(object.email) ? globalThis.String(object.email) : '',
      thumbnailImagePath: isSet(object.thumbnail_image_path)
        ? globalThis.String(object.thumbnail_image_path)
        : undefined,
      joinTime: isSet(object.join_time)
        ? fromJsonTimestamp(object.join_time)
        : undefined,
      lastLogin: isSet(object.last_login)
        ? fromJsonTimestamp(object.last_login)
        : undefined,
      totalNumVisitDays: isSet(object.total_num_visit_days)
        ? globalThis.Number(object.total_num_visit_days)
        : 0,
      isAdmin: isSet(object.is_admin)
        ? globalThis.Boolean(object.is_admin)
        : false,
      numContinuousLoginDays: isSet(object.num_continuous_login_days)
        ? globalThis.Number(object.num_continuous_login_days)
        : 0,
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
      serviceCheckTime: isSet(object.service_check_time)
        ? fromJsonTimestamp(object.service_check_time)
        : undefined,
      privacyCheckTime: isSet(object.privacy_check_time)
        ? fromJsonTimestamp(object.privacy_check_time)
        : undefined,
      marketingCheckTime: isSet(object.marketing_check_time)
        ? fromJsonTimestamp(object.marketing_check_time)
        : undefined,
      loginType: isSet(object.login_type)
        ? loginTypeFromJSON(object.login_type)
        : 0,
      productCategoryList: globalThis.Array.isArray(
        object?.product_category_list,
      )
        ? object.product_category_list.map((e: any) =>
            productCategoryFromJSON(e),
          )
        : [],
      isTest: isSet(object.is_test)
        ? globalThis.Boolean(object.is_test)
        : false,
    };
  },

  toJSON(message: ExternalUserInfo): unknown {
    const obj: any = {};
    if (message.name !== '') {
      obj.name = message.name;
    }
    if (message.email !== '') {
      obj.email = message.email;
    }
    if (message.thumbnailImagePath !== undefined) {
      obj.thumbnail_image_path = message.thumbnailImagePath;
    }
    if (message.joinTime !== undefined) {
      obj.join_time = message.joinTime.toISOString();
    }
    if (message.lastLogin !== undefined) {
      obj.last_login = message.lastLogin.toISOString();
    }
    if (message.totalNumVisitDays !== 0) {
      obj.total_num_visit_days = Math.round(message.totalNumVisitDays);
    }
    if (message.isAdmin !== false) {
      obj.is_admin = message.isAdmin;
    }
    if (message.numContinuousLoginDays !== 0) {
      obj.num_continuous_login_days = Math.round(
        message.numContinuousLoginDays,
      );
    }
    if (message.role !== 0) {
      obj.role = roleToJSON(message.role);
    }
    if (message.serviceCheckTime !== undefined) {
      obj.service_check_time = message.serviceCheckTime.toISOString();
    }
    if (message.privacyCheckTime !== undefined) {
      obj.privacy_check_time = message.privacyCheckTime.toISOString();
    }
    if (message.marketingCheckTime !== undefined) {
      obj.marketing_check_time = message.marketingCheckTime.toISOString();
    }
    if (message.loginType !== 0) {
      obj.login_type = loginTypeToJSON(message.loginType);
    }
    if (message.productCategoryList?.length) {
      obj.product_category_list = message.productCategoryList.map((e) =>
        productCategoryToJSON(e),
      );
    }
    if (message.isTest !== false) {
      obj.is_test = message.isTest;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExternalUserInfo>, I>>(
    base?: I,
  ): ExternalUserInfo {
    return ExternalUserInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExternalUserInfo>, I>>(
    object: I,
  ): ExternalUserInfo {
    const message = createBaseExternalUserInfo();
    message.name = object.name ?? '';
    message.email = object.email ?? '';
    message.thumbnailImagePath = object.thumbnailImagePath ?? undefined;
    message.joinTime = object.joinTime ?? undefined;
    message.lastLogin = object.lastLogin ?? undefined;
    message.totalNumVisitDays = object.totalNumVisitDays ?? 0;
    message.isAdmin = object.isAdmin ?? false;
    message.numContinuousLoginDays = object.numContinuousLoginDays ?? 0;
    message.role = object.role ?? 0;
    message.serviceCheckTime = object.serviceCheckTime ?? undefined;
    message.privacyCheckTime = object.privacyCheckTime ?? undefined;
    message.marketingCheckTime = object.marketingCheckTime ?? undefined;
    message.loginType = object.loginType ?? 0;
    message.productCategoryList =
      object.productCategoryList?.map((e) => e) || [];
    message.isTest = object.isTest ?? false;
    return message;
  },
};

function createBaseExternalUserInfoWithUserIdInfo(): ExternalUserInfoWithUserIdInfo {
  return {
    name: '',
    email: '',
    thumbnailImagePath: undefined,
    joinTime: undefined,
    lastLogin: undefined,
    totalNumVisitDays: 0,
    isAdmin: false,
    numContinuousLoginDays: 0,
    role: 0,
    serviceCheckTime: undefined,
    privacyCheckTime: undefined,
    marketingCheckTime: undefined,
    loginType: 0,
    userId: 0,
    productCategoryList: [],
    isTest: false,
  };
}

export const ExternalUserInfoWithUserIdInfo: MessageFns<ExternalUserInfoWithUserIdInfo> =
  {
    encode(
      message: ExternalUserInfoWithUserIdInfo,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.name !== '') {
        writer.uint32(10).string(message.name);
      }
      if (message.email !== '') {
        writer.uint32(18).string(message.email);
      }
      if (message.thumbnailImagePath !== undefined) {
        writer.uint32(26).string(message.thumbnailImagePath);
      }
      if (message.joinTime !== undefined) {
        Timestamp.encode(
          toTimestamp(message.joinTime),
          writer.uint32(34).fork(),
        ).join();
      }
      if (message.lastLogin !== undefined) {
        Timestamp.encode(
          toTimestamp(message.lastLogin),
          writer.uint32(42).fork(),
        ).join();
      }
      if (message.totalNumVisitDays !== 0) {
        writer.uint32(48).int32(message.totalNumVisitDays);
      }
      if (message.isAdmin !== false) {
        writer.uint32(56).bool(message.isAdmin);
      }
      if (message.numContinuousLoginDays !== 0) {
        writer.uint32(64).int32(message.numContinuousLoginDays);
      }
      if (message.role !== 0) {
        writer.uint32(72).int32(message.role);
      }
      if (message.serviceCheckTime !== undefined) {
        Timestamp.encode(
          toTimestamp(message.serviceCheckTime),
          writer.uint32(82).fork(),
        ).join();
      }
      if (message.privacyCheckTime !== undefined) {
        Timestamp.encode(
          toTimestamp(message.privacyCheckTime),
          writer.uint32(90).fork(),
        ).join();
      }
      if (message.marketingCheckTime !== undefined) {
        Timestamp.encode(
          toTimestamp(message.marketingCheckTime),
          writer.uint32(98).fork(),
        ).join();
      }
      if (message.loginType !== 0) {
        writer.uint32(104).int32(message.loginType);
      }
      if (message.userId !== 0) {
        writer.uint32(112).int32(message.userId);
      }
      writer.uint32(122).fork();
      for (const v of message.productCategoryList) {
        writer.int32(v);
      }
      writer.join();
      if (message.isTest !== false) {
        writer.uint32(128).bool(message.isTest);
      }
      return writer;
    },

    decode(
      input: BinaryReader | Uint8Array,
      length?: number,
    ): ExternalUserInfoWithUserIdInfo {
      const reader =
        input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseExternalUserInfoWithUserIdInfo();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.name = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.email = reader.string();
            continue;
          }
          case 3: {
            if (tag !== 26) {
              break;
            }

            message.thumbnailImagePath = reader.string();
            continue;
          }
          case 4: {
            if (tag !== 34) {
              break;
            }

            message.joinTime = fromTimestamp(
              Timestamp.decode(reader, reader.uint32()),
            );
            continue;
          }
          case 5: {
            if (tag !== 42) {
              break;
            }

            message.lastLogin = fromTimestamp(
              Timestamp.decode(reader, reader.uint32()),
            );
            continue;
          }
          case 6: {
            if (tag !== 48) {
              break;
            }

            message.totalNumVisitDays = reader.int32();
            continue;
          }
          case 7: {
            if (tag !== 56) {
              break;
            }

            message.isAdmin = reader.bool();
            continue;
          }
          case 8: {
            if (tag !== 64) {
              break;
            }

            message.numContinuousLoginDays = reader.int32();
            continue;
          }
          case 9: {
            if (tag !== 72) {
              break;
            }

            message.role = reader.int32() as any;
            continue;
          }
          case 10: {
            if (tag !== 82) {
              break;
            }

            message.serviceCheckTime = fromTimestamp(
              Timestamp.decode(reader, reader.uint32()),
            );
            continue;
          }
          case 11: {
            if (tag !== 90) {
              break;
            }

            message.privacyCheckTime = fromTimestamp(
              Timestamp.decode(reader, reader.uint32()),
            );
            continue;
          }
          case 12: {
            if (tag !== 98) {
              break;
            }

            message.marketingCheckTime = fromTimestamp(
              Timestamp.decode(reader, reader.uint32()),
            );
            continue;
          }
          case 13: {
            if (tag !== 104) {
              break;
            }

            message.loginType = reader.int32() as any;
            continue;
          }
          case 14: {
            if (tag !== 112) {
              break;
            }

            message.userId = reader.int32();
            continue;
          }
          case 15: {
            if (tag === 120) {
              message.productCategoryList.push(reader.int32() as any);

              continue;
            }

            if (tag === 122) {
              const end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) {
                message.productCategoryList.push(reader.int32() as any);
              }

              continue;
            }

            break;
          }
          case 16: {
            if (tag !== 128) {
              break;
            }

            message.isTest = reader.bool();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): ExternalUserInfoWithUserIdInfo {
      return {
        name: isSet(object.name) ? globalThis.String(object.name) : '',
        email: isSet(object.email) ? globalThis.String(object.email) : '',
        thumbnailImagePath: isSet(object.thumbnail_image_path)
          ? globalThis.String(object.thumbnail_image_path)
          : undefined,
        joinTime: isSet(object.join_time)
          ? fromJsonTimestamp(object.join_time)
          : undefined,
        lastLogin: isSet(object.last_login)
          ? fromJsonTimestamp(object.last_login)
          : undefined,
        totalNumVisitDays: isSet(object.total_num_visit_days)
          ? globalThis.Number(object.total_num_visit_days)
          : 0,
        isAdmin: isSet(object.is_admin)
          ? globalThis.Boolean(object.is_admin)
          : false,
        numContinuousLoginDays: isSet(object.num_continuous_login_days)
          ? globalThis.Number(object.num_continuous_login_days)
          : 0,
        role: isSet(object.role) ? roleFromJSON(object.role) : 0,
        serviceCheckTime: isSet(object.service_check_time)
          ? fromJsonTimestamp(object.service_check_time)
          : undefined,
        privacyCheckTime: isSet(object.privacy_check_time)
          ? fromJsonTimestamp(object.privacy_check_time)
          : undefined,
        marketingCheckTime: isSet(object.marketing_check_time)
          ? fromJsonTimestamp(object.marketing_check_time)
          : undefined,
        loginType: isSet(object.login_type)
          ? loginTypeFromJSON(object.login_type)
          : 0,
        userId: isSet(object.user_id) ? globalThis.Number(object.user_id) : 0,
        productCategoryList: globalThis.Array.isArray(
          object?.product_category_list,
        )
          ? object.product_category_list.map((e: any) =>
              productCategoryFromJSON(e),
            )
          : [],
        isTest: isSet(object.is_test)
          ? globalThis.Boolean(object.is_test)
          : false,
      };
    },

    toJSON(message: ExternalUserInfoWithUserIdInfo): unknown {
      const obj: any = {};
      if (message.name !== '') {
        obj.name = message.name;
      }
      if (message.email !== '') {
        obj.email = message.email;
      }
      if (message.thumbnailImagePath !== undefined) {
        obj.thumbnail_image_path = message.thumbnailImagePath;
      }
      if (message.joinTime !== undefined) {
        obj.join_time = message.joinTime.toISOString();
      }
      if (message.lastLogin !== undefined) {
        obj.last_login = message.lastLogin.toISOString();
      }
      if (message.totalNumVisitDays !== 0) {
        obj.total_num_visit_days = Math.round(message.totalNumVisitDays);
      }
      if (message.isAdmin !== false) {
        obj.is_admin = message.isAdmin;
      }
      if (message.numContinuousLoginDays !== 0) {
        obj.num_continuous_login_days = Math.round(
          message.numContinuousLoginDays,
        );
      }
      if (message.role !== 0) {
        obj.role = roleToJSON(message.role);
      }
      if (message.serviceCheckTime !== undefined) {
        obj.service_check_time = message.serviceCheckTime.toISOString();
      }
      if (message.privacyCheckTime !== undefined) {
        obj.privacy_check_time = message.privacyCheckTime.toISOString();
      }
      if (message.marketingCheckTime !== undefined) {
        obj.marketing_check_time = message.marketingCheckTime.toISOString();
      }
      if (message.loginType !== 0) {
        obj.login_type = loginTypeToJSON(message.loginType);
      }
      if (message.userId !== 0) {
        obj.user_id = Math.round(message.userId);
      }
      if (message.productCategoryList?.length) {
        obj.product_category_list = message.productCategoryList.map((e) =>
          productCategoryToJSON(e),
        );
      }
      if (message.isTest !== false) {
        obj.is_test = message.isTest;
      }
      return obj;
    },

    create<I extends Exact<DeepPartial<ExternalUserInfoWithUserIdInfo>, I>>(
      base?: I,
    ): ExternalUserInfoWithUserIdInfo {
      return ExternalUserInfoWithUserIdInfo.fromPartial(base ?? ({} as any));
    },
    fromPartial<
      I extends Exact<DeepPartial<ExternalUserInfoWithUserIdInfo>, I>,
    >(object: I): ExternalUserInfoWithUserIdInfo {
      const message = createBaseExternalUserInfoWithUserIdInfo();
      message.name = object.name ?? '';
      message.email = object.email ?? '';
      message.thumbnailImagePath = object.thumbnailImagePath ?? undefined;
      message.joinTime = object.joinTime ?? undefined;
      message.lastLogin = object.lastLogin ?? undefined;
      message.totalNumVisitDays = object.totalNumVisitDays ?? 0;
      message.isAdmin = object.isAdmin ?? false;
      message.numContinuousLoginDays = object.numContinuousLoginDays ?? 0;
      message.role = object.role ?? 0;
      message.serviceCheckTime = object.serviceCheckTime ?? undefined;
      message.privacyCheckTime = object.privacyCheckTime ?? undefined;
      message.marketingCheckTime = object.marketingCheckTime ?? undefined;
      message.loginType = object.loginType ?? 0;
      message.userId = object.userId ?? 0;
      message.productCategoryList =
        object.productCategoryList?.map((e) => e) || [];
      message.isTest = object.isTest ?? false;
      return message;
    },
  };

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never;
    };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === 'string') {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
