declare let gtag;

interface HasFromJson<ResponseType> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromJSON: (object: any) => ResponseType;
}

interface HasToJson<RequestType> {
  toJSON: (message: RequestType) => unknown;
}

export class ApiCaching<RequestType, ResponseType> {
  address: string;
  cacheTime: number;

  constructor(address: string, cacheTime: number) {
    this.address = address;
    this.cacheTime = cacheTime;
  }

  public getApiCacheKey = (request: RequestType, userLoggedIn: boolean) => {
    const CACHE_VERSION = 'v0.1';

    return JSON.stringify({
      address: this.address,
      request,
      version: CACHE_VERSION,
      login: userLoggedIn,
    });
  };

  public getResponseToLocalStorage = (
    request: RequestType,
    fromJSON: (object: any) => ResponseType,
    userLoggedIn: boolean,
  ) => {
    const cachedRequestJson = window.sessionStorage.getItem(
      this.getApiCacheKey(request, userLoggedIn),
    );

    if (cachedRequestJson) {
      try {
        const cachedResponse = JSON.parse(cachedRequestJson) satisfies {
          cacheTime: number;
          response: ResponseType;
        };

        const parsedResponse = fromJSON(cachedResponse.response);

        if (Date.now() - cachedResponse.cacheTime <= this.cacheTime) {
          return parsedResponse;
        } else {
          return undefined;
        }
      } catch (err) {
        localStorage.removeItem(cachedRequestJson);
        return undefined;
      }
    }

    return undefined;
  };

  public saveResponseToLocalStorage = (
    request: RequestType,
    response: string,
    userLoggedIn: boolean,
  ) => {
    window.sessionStorage.setItem(
      this.getApiCacheKey(request, userLoggedIn),
      JSON.stringify({
        cacheTime: Date.now(),
        response,
      }),
    );
  };
}

export const LOGIN_ERROR_DEFAULT_REDIRECT_MSG =
  'Unauthorized : User is not logged in. Redirect to login page.';

async function postApiInvoker<RequestType, ResponseType>(
  address: string,
  request: RequestType,
  requestConverter: HasToJson<RequestType>,
  responseConverter: HasFromJson<ResponseType>,
  userLoggedIn: boolean,
  onLoginError: () => void,
  apiCacing?: ApiCaching<RequestType, ResponseType>,
): Promise<ResponseType> {
  const cachedResponse = apiCacing?.getResponseToLocalStorage(
    request,
    responseConverter.fromJSON,
    userLoggedIn,
  );

  if (cachedResponse) {
    return cachedResponse;
  }

  const response = await fetch(address, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestConverter.toJSON(request)),
  });

  const response_json = await response.json();

  if (response.status == 200 && apiCacing) {
    apiCacing.saveResponseToLocalStorage(request, response_json, userLoggedIn);
  }

  const converted = responseConverter.fromJSON(response_json);

  if (
    response.status == /* Unauthorized */ 401 &&
    converted['error'] == LOGIN_ERROR_DEFAULT_REDIRECT_MSG
  ) {
    onLoginError();
  }

  gtag('event', address, {
    url: window.location.href,
  });

  return converted;
}

function sendPostRequest<RequestType, ResponseType>(
  address: string,
  requestConverter: HasToJson<RequestType>,
  responseConverter: HasFromJson<ResponseType>,
  cacheTime?: number,
): (
  request: RequestType,
  onLoginError: () => void,
  userLoggedIn?: boolean,
) => Promise<ResponseType> {
  const apiCacing = cacheTime
    ? new ApiCaching<RequestType, ResponseType>(address, cacheTime)
    : undefined;

  const caller = (
    request: RequestType,
    onLoginError: () => void,
    userLoggedIn?: boolean,
  ): Promise<ResponseType> => {
    return postApiInvoker<RequestType, ResponseType>(
      address,
      request,
      requestConverter,
      responseConverter,
      userLoggedIn ?? false,
      onLoginError,
      apiCacing,
    );
  };

  return caller;
}

export default sendPostRequest;
